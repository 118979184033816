var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.loaded === false ? 'div' : 'div',{tag:"component"},[_c('b-card',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-media',{staticClass:"mb-1 mt-2 ml-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.currentItem.logo ? _vm.currentItem.logo.url : null,"size":"90px","rounded":"","icon":"DownloadIcon"}},[(!_vm.currentItem.logo)?_c('feather-icon',{attrs:{"icon":"ImageIcon","size":"30"}}):_vm._e()],1)]},proxy:true}],null,true)},[_c('h4',{staticClass:"mb-1 ml-1"},[_vm._v(_vm._s(_vm.$t("Your company logo")))]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.addLogo}}),(_vm.currentItem.logo && _vm.currentItem.logo.url)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("Update logo"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("Add logo"))+" ")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),(_vm.currentItem.logo && _vm.currentItem.logo.url)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"danger"},on:{"click":_vm.deleteLogo}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("Remove logo")))]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)]),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1 mt-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Company name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Firm')},model:{value:(_vm.currentItem.firm),callback:function ($$v) {_vm.$set(_vm.currentItem, "firm", $$v)},expression:"currentItem.firm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('firm'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.firm[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1 mt-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Organization Type')}},[_c('b-form-select',{attrs:{"options":_vm.organizationTypes,"placeholder":_vm.$t('Please select an Organization Type')},model:{value:(_vm.currentItem.organization_type),callback:function ($$v) {_vm.$set(_vm.currentItem, "organization_type", $$v)},expression:"currentItem.organization_type"}})],1)],1)],1),(_vm.showOtherOrgType)?_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"6"}}),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Other Organization Type')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Organization Type')},model:{value:(_vm.currentItem.other_organization_type),callback:function ($$v) {_vm.$set(_vm.currentItem, "other_organization_type", $$v)},expression:"currentItem.other_organization_type"}})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Street')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Street'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"street","placeholder":_vm.$t('Street')},model:{value:(_vm.currentItem.street),callback:function ($$v) {_vm.$set(_vm.currentItem, "street", $$v)},expression:"currentItem.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('street'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.street[0])))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"name","options":_vm.countries,"reduce":function (option) { return option.code; }},model:{value:(_vm.currentItem.country),callback:function ($$v) {_vm.$set(_vm.currentItem, "country", $$v)},expression:"currentItem.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('country'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.country[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Postal adress ZIP/postal code')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Postal'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postal","placeholder":_vm.$t('Postal')},model:{value:(_vm.currentItem.postal),callback:function ($$v) {_vm.$set(_vm.currentItem, "postal", $$v)},expression:"currentItem.postal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('postal'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.postal[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('City')}},[_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","placeholder":_vm.$t('City')},model:{value:(_vm.currentItem.city),callback:function ($$v) {_vm.$set(_vm.currentItem, "city", $$v)},expression:"currentItem.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('city'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.city[0])))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Wesite')}},[_c('b-form-input',{attrs:{"id":"website","placeholder":_vm.$t('Website')},model:{value:(_vm.currentItem.website),callback:function ($$v) {_vm.$set(_vm.currentItem, "website", $$v)},expression:"currentItem.website"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('No. of Employees')}},[_c('b-form-select',{attrs:{"options":_vm.rangeEmployees,"placeholder":_vm.$t('Please select an range of employees')},model:{value:(_vm.currentItem.number_of_employees),callback:function ($$v) {_vm.$set(_vm.currentItem, "number_of_employees", $$v)},expression:"currentItem.number_of_employees"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12 mt-2"}},[_c('b-button',{attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }