let config = {
    organization_types: [
        {
            value: 1,
            text: "Education Institution",
        },
        {
            value: 2,
            text: "Non Profit",
        },
        {
            value: 3,
            text: "Self Employed",
        },
        {
            value: 4,
            text: "Corporation",
        },
        {
            value: 5,
            text: "Independent Partnership",
        },
        {
            value: 6,
            text: "Government Agency",
        },
        {
            value: 7,
            text: "Other",
        },
    ],
    range_of_employees: [
        {
            value: 1,
            text: "1 Employee",
        },
        {
            value: 2,
            text: "2-10",
        },
        {
            value: 3,
            text: "11-50",
        },
        {
            value: 4,
            text: "51-200",
        },
        {
            value: 5,
            text: "201-500",
        },
        {
            value: 6,
            text: "501-2000",
        },
        {
            value: 7,
            text: "More Than 2000",
        },
    ]
}
export { config }