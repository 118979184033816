<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-media class="mb-1 mt-2 ml-2">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="currentItem.logo ? currentItem.logo.url : null"
              size="90px"
              rounded
              icon="DownloadIcon"
            >
              <feather-icon
                v-if="!currentItem.logo"
                icon="ImageIcon"
                size="30"
              />
            </b-avatar>
          </template>
          <h4 class="mb-1 ml-1">{{ $t("Your company logo") }}</h4>
          <div class="d-flex flex-wrap">
            <b-button
              class="ml-1"
              variant="success"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="addLogo"
              />
              <span v-if="currentItem.logo && currentItem.logo.url">
                {{ $t("Update logo") }}
              </span>
              <span v-else>
                {{ $t("Add logo") }}
              </span>
              <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
            </b-button>
            <b-button
              v-if="currentItem.logo && currentItem.logo.url"
              variant="danger"
              class="ml-1"
              @click="deleteLogo"
            >
              <span class="d-none d-sm-inline">{{ $t("Remove logo") }}</span>
              <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
            </b-button>
          </div>
        </b-media>
        <b-form
          class="p-2"
          @submit.stop.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- <h3 class="mt-2 mb-1">{{ $t('Address') }}</h3> -->
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-1">
              <b-form-group :label="$t('Company name')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Company name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="currentItem.firm"
                    :placeholder="$t('Firm')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="Object.keys(validation).includes('firm')"
                    class="text-danger"
                    >{{ $t(validation.firm[0]) }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1 mt-1">
              <b-form-group :label="$t('Organization Type')">
                <b-form-select
                  v-model="currentItem.organization_type"
                  :options="organizationTypes"
                  :placeholder="$t('Please select an Organization Type')"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="showOtherOrgType">
            <b-col md="6" xl="6"> </b-col>
            <b-col md="6" xl="6">
              <b-form-group :label="$t('Other Organization Type')">
                <b-form-input
                  v-model="currentItem.other_organization_type"
                  :placeholder="$t('Organization Type')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-1">
              <b-form-group :label="$t('Street')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Street')"
                  rules="required"
                >
                  <b-form-input
                    v-model="currentItem.street"
                    id="street"
                    :placeholder="$t('Street')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="Object.keys(validation).includes('street')"
                    class="text-danger"
                    >{{ $t(validation.street[0]) }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" xl="4" class="mb-1">
              <b-form-group :label="$t('Country')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Country')"
                  rules="required"
                >
                  <v-select
                    v-model="currentItem.country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="name"
                    :options="countries"
                    :reduce="(option) => option.code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="Object.keys(validation).includes('country')"
                    class="text-danger"
                    >{{ $t(validation.country[0]) }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" xl="4" class="mb-1">
              <b-form-group :label="$t('Postal adress ZIP/postal code')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Postal')"
                  rules="required"
                >
                  <b-form-input
                    v-model="currentItem.postal"
                    id="postal"
                    :placeholder="$t('Postal')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="Object.keys(validation).includes('postal')"
                    class="text-danger"
                    >{{ $t(validation.postal[0]) }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" xl="4" class="mb-1">
              <b-form-group :label="$t('City')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('City')"
                  rules="required"
                >
                  <b-form-input
                    v-model="currentItem.city"
                    id="city"
                    :placeholder="$t('City')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="Object.keys(validation).includes('city')"
                    class="text-danger"
                    >{{ $t(validation.city[0]) }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" xl="4" class="mb-1">
              <b-form-group :label="$t('Wesite')">
                <b-form-input
                  v-model="currentItem.website"
                  id="website"
                  :placeholder="$t('Website')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" xl="4" class="mb-1">
              <b-form-group :label="$t('No. of Employees')">
                <b-form-select
                  v-model="currentItem.number_of_employees"
                  :options="rangeEmployees"
                  :placeholder="$t('Please select an range of employees')"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12 mt-2">
              <b-button
                variant="success"
                type="submit"
                @click.prevent="onSubmit"
              >
                {{ $t("Save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </component>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BRow,
  BSidebar,
  BToast,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import moment from "moment";
import { config } from "@/mixins/config";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormSelect,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    required
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  watch: {
    "currentItem.organization_type": function (val) {
      this.showOtherOrgType = val === 7 ? true : false;
    },
  },
  data() {
    return {
      firstname: "",
      currentItem: {
        logo: {
          url: "",
        },
        id: "",
        postal: "",
        street: "",
        city: "",
        invoice_firstname: "",
        invoice_postal: "",
        invoice_street: "",
        invoice_city: "",
        organization_type: "",
        other_organization_type: "",
        website: "",
        country: "",
        number_of_employees: "",
      },
      loaded: true,
      validation: {},
      validate: false,
      organizationTypes: config.organization_types,
      rangeEmployees: config.range_of_employees,
      showOtherOrgType: false,
      countries: [],
    };
  },

  methods: {
    updateItem() {
      this.$refs.refFormObserver.validate().then((success) => {
        this.validation = {};

        if (success) {
          this.$http
            .put(`/client/companyprofile`, this.currentItem)
            .then((res) => {
              this.$bvToast.toast(this.$i18n.t("Item updated successfully"), {
                title: this.$i18n.t("Item updated successfully"),
                variant: "success",
                toaster: "b-toaster-bottom-right",
              });
            })
            .catch((error) => {
              if (error.response.status == 422) {
                this.validation = error.response.data.errors;
                this.validate = true;
              } else {
                this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                  title: this.$i18n.t(error.response.data.message),
                  variant: "danger",
                  toaster: "b-toaster-bottom-right",
                });
              }
            });
        }
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    addLogo(event) {
      if (event) event.preventDefault();

      const reader = new FileReader();
      let file = this.$refs.refInputEl.files[0];
      if (file) {
        reader.readAsDataURL(file);

        reader.onload = (evt) => {
          let base64 = evt.target.result;

          this.currentItem.logo = this.currentItem.logo
            ? this.currentItem.logo
            : {};

          this.currentItem.logo.url = base64;

          this.uploadLogo(file);
        };
      }
    },

    uploadLogo(base64) {
      let formData = new FormData();
      formData.append("files", base64);

      this.$http
        .post(`/client/companyprofile/logoupload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$bvToast.toast(this.$i18n.t("Logo uploaded successfully"), {
            title: this.$i18n.t("Success"),
            variant: "success",
            toaster: "b-toaster-bottom-right",
          });
          localStorage.setItem("company_logo", response.data.logo.file);
          this.$store.dispatch("GET_COMPANY_LOGO", response.data.logo.file);
        })
        .catch((e) => {
          this.$bvToast.toast(this.$i18n.t("Logo not uploaded"), {
            title: this.$i18n.t("Error"),
            variant: "danger",
            toaster: "b-toaster-bottom-right",
          });
        });
    },
    deleteLogo() {
      this.$http
        .delete(`/client/companyprofile/logoremove`)
        .then((response) => {
          this.currentItem.logo = null;
          localStorage.setItem("company_logo", "");
          this.$store.dispatch("GET_COMPANY_LOGO", null);
          this.$bvToast.toast(this.$i18n.t("Logo deleted"), {
            title: this.$i18n.t("Success"),
            variant: "success",
            toaster: "b-toaster-bottom-right",
          });
        })
        .catch((e) => {
          this.$bvToast.toast(this.$i18n.t("Logo not deleted"), {
            title: this.$i18n.t("Error"),
            variant: "danger",
            toaster: "b-toaster-bottom-right",
          });
        });
    },

    onSubmit() {
      this.updateItem();
    },

    onBirthdayDatePickerChange(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
      this.currentItem.birthday_text = moment(
        ctx.selectedYMD,
        "YYYY-MM-DD"
      ).format("DD.MM.YYYY");
    },
    onBirthdayInput() {
      let date = moment(this.currentItem.birthday_text, "DD.MM.YYYY");

      if (date.isValid()) {
        this.currentItem.birthday = date.format("YYYY-MM-DD");
      } else {
        this.currentItem.birthday = null;
        this.currentItem.birthday_text = "";
      }
    },
    getCountries() {
      this.$http
        .get("get-countries")
        .then((response) => {
          this.countries = response.data;
        })
        .catch((error) => {
          console.debug(error);
        });
    },
  },

  mounted() {
    this.helperGetItem(`/client/companyprofile`);
    this.getCountries();
  },
};
</script>
